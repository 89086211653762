import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faInstagram,
  faXTwitter,
  faFacebookF,
  faYoutube,
  faSquareFacebook,
  faSquareInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { faVenusMars } from '@fortawesome/pro-light-svg-icons';
import { faCopy as farCopy, faStar as farStar } from '@fortawesome/pro-regular-svg-icons';
import {
  faCar,
  faFile,
  faFlag,
  faMars,
  faPen,
  faTags,
  faPlus,
  faKey,
  faUser,
  faUsers,
  faGlobe,
  faFire,
  faStore,
  faImage,
  faTruck,
  faVideo,
  faPhone,
  faVenus,
  faXmark,
  faFilter,
  faCrown,
  faMobile,
  faCamera,
  faUserTie,
  faComment,
  faSortUp,
  faSitemap,
  faEllipsis,
  faUserGroup,
  faComments,
  faUserPlus,
  faSpinner,
  faCircleDot,
  faEnvelope,
  faStarSharp,
  faCirclePlus,
  faUserXmark,
  faThumbsUp,
  faBookmark,
  faBarsFilter,
  faNoteSticky,
  faCalculator,
  faFileImport,
  faLocationDot,
  faArrowLeft,
  faChevronUp,
  faCircleXmark,
  faPaperclip,
  faChevronDown,
  faUserFriends,
  faCircleMinus,
  faChevronLeft,
  faCircleInfo,
  faShareNodes,
  faGenderless,
  faStarHalfAlt,
  faCircleNotch,
  faExclamation,
  faSquareCheck,
  faCircleCheck,
  faStarSharpHalf,
  faCircleQuestion,
  faEarthAmericas,
  faCakeCandles,
  faCartShopping,
  faTemperatureFull,
  faMoneyCheckDollar,
  faCopy as fasCopy,
  faStar as fasStar,
  faEllipsisVertical,
  faTemperatureHalf,
  faTemperatureQuarter,
  faMagnifyingGlassPlus,
  faSquareArrowUpRight,
  faTriangleExclamation,
  faArrowUpRightFromSquare,
  faTemperatureThreeQuarters,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faPen,
  faKey,
  faCar,
  faPlus,
  faTruck,
  faImage,
  faFlag,
  faUsers,
  faGlobe,
  fasCopy,
  farCopy,
  faUser,
  faTags,
  faStore,
  faCamera,
  faSortUp,
  faXmark,
  fasStar,
  faVideo,
  farStar,
  faMars,
  faFile,
  faVenus,
  faFire,
  faCrown,
  faMobile,
  faPhone,
  faComment,
  faUserTie,
  faFilter,
  faSpinner,
  faThumbsUp,
  faEllipsis,
  faUserGroup,
  faCircleDot,
  faArrowLeft,
  faInstagram,
  faXTwitter,
  faFacebookF,
  faYoutube,
  faSitemap,
  faBookmark,
  faComments,
  faUserPlus,
  faEnvelope,
  faPaperclip,
  faVenusMars,
  faUserXmark,
  faChevronUp,
  faCirclePlus,
  faGenderless,
  faStarSharp,
  faCircleInfo,
  faCalculator,
  faBarsFilter,
  faFileImport,
  faChevronLeft,
  faNoteSticky,
  faShareNodes,
  faExclamation,
  faCircleCheck,
  faCircleMinus,
  faStarHalfAlt,
  faCircleXmark,
  faUserFriends,
  faLocationDot,
  faChevronDown,
  faSquareCheck,
  faCircleNotch,
  faCakeCandles,
  faCartShopping,
  faEarthAmericas,
  faStarSharpHalf,
  faCircleQuestion,
  faSquareFacebook,
  faSquareInstagram,
  faTemperatureHalf,
  faTemperatureFull,
  faMoneyCheckDollar,
  faEllipsisVertical,
  faSquareArrowUpRight,
  faTemperatureQuarter,
  faTriangleExclamation,
  faMagnifyingGlassPlus,
  faArrowUpRightFromSquare,
  faTemperatureThreeQuarters
);

dom.watch();
